import React, { lazy } from "react";

const Dashboard = lazy(() => import("pages/dashboard"));
const Companies = lazy(() => import("pages/companies"));
const CreateCompany = lazy(() => import("pages/companies/Create"));
const PostAJob = lazy(() => import("pages/companies/PostAJob"));
const JobDetails = lazy(() => import("pages/companies/JobDetails"));
const CompanyDetails = lazy(() => import("pages/companies/Details"));
const BlogCategories = lazy(() => import("pages/blogCategories"));
const ResponsibiltiesList = lazy(() => import("pages/responsibiltiesList"));
const ResponsibilitieDetails = lazy(() =>
  import("pages/responsibiltiesList/Detail")
);
const BecomeRecruiterRequests = lazy(() =>
  import("pages/becomeRecruiterRequests")
);
const BecomeRecruiterRequestDetails = lazy(() =>
  import("pages/becomeRecruiterRequests/Detail")
);
const Universities = lazy(() => import("pages/universities"));
const UniversitiesDetails = lazy(() => import("pages/universities/Details"));
const CreateUniversity = lazy(() => import("pages/universities/Create"));
const Industries = lazy(() => import("pages/industries"));
const Designations = lazy(() => import("pages/designations"));
const Users = lazy(() => import("pages/users"));
const BlogTypes = lazy(() => import("pages/blogTypes"));
const Blogs = lazy(() => import("pages/blogs"));
const CreateBlog = lazy(() => import("pages/blogs/Create"));
const BlogDetails = lazy(() => import("pages/blogs/Details"));
const Jobs = lazy(() => import("pages/jobs"));
const Templates = lazy(() => import("pages/templates"));
const CreateTemplate = lazy(() => import("pages/templates/Create"));
const Candidates = lazy(() => import("pages/candidates"));
const CandidateDetails = lazy(() => import("pages/candidates/Details"));
const Faqs = lazy(() => import("pages/faqs"));
const FaqDetails = lazy(() => import("pages/faqs/Details"));
const Agreement = lazy(() => import("pages/agreement"));
const Terms = lazy(() => import("components/agreement/Pages/Terms"));
const Policy = lazy(() => import("components/agreement/Pages/Policy"));

// Job Categories
const JobCategories = lazy(() => import("components/jobCategories"));

const ScreenRoutes = [
  { path: "/admin/dashboard", name: "Dashboard", element: <Dashboard /> },
  { path: "/admin/companies", name: "Companies", element: <Companies /> },
  {
    path: "/admin/companies/create",
    name: "CreateCompany",
    element: <CreateCompany />,
  },
  {
    path: "/admin/companies/update/:id",
    name: "CreateCompany",
    element: <CreateCompany />,
  },
  {
    path: "/admin/companies/:id",
    name: "CompanyDetails",
    element: <CompanyDetails />,
  },
  {
    path: "/admin/companies/:id/postAJob",
    name: "PostAJob",
    element: <PostAJob />,
  },
  {
    path: "/admin/companies/:id/postAJob/:jobId",
    name: "PostAJob",
    element: <PostAJob />,
  },
  {
    path: "/admin/companies/:id/:jobId",
    name: "JobDetails",
    element: <JobDetails />,
  },
  {
    path: "/admin/blogCategories",
    name: "BlogCategories",
    element: <BlogCategories />,
  },

  {
    path: "/admin/becomeRecruiterRequests",
    name: "BecomeRecruiterRequests",
    element: <BecomeRecruiterRequests />,
  },
  {
    path: "/admin/becomeRecruiterRequests/:id",
    name: "BecomeRecruiterRequestDetails",
    element: <BecomeRecruiterRequestDetails />,
  },
  {
    path: "/admin/becomeRecruiterRequests/:requestId/create",
    name: "CreateCompany",
    element: <CreateCompany />,
  },
  {
    path: "/admin/universities",
    name: "Universities",
    element: <Universities />,
  },
  {
    path: "/admin/universities/:id",
    name: "UniversitiesDetails",
    element: <UniversitiesDetails />,
  },
  {
    path: "/admin/universities/create",
    name: "CreateUniversity",
    element: <CreateUniversity />,
  },
  {
    path: "/admin/universities/update/:id",
    name: "CreateUniversity",
    element: <CreateUniversity />,
  },
  {
    path: "/admin/industries",
    name: "Industries",
    element: <Industries />,
  },
  {
    path: "/admin/designations",
    name: "Designations",
    element: <Designations />,
  },
  {
    path: "/admin/designations/:id",
    name: "ResponsibiltiesList",
    element: <ResponsibiltiesList />,
  },
  {
    path: "/admin/designations/:id/:repId",
    name: "ResponsibilitieDetails",
    element: <ResponsibilitieDetails />,
  },
  {
    path: "/admin/users",
    name: "Users",
    element: <Users />,
  },
  {
    path: "/admin/blogTypes",
    name: "BlogTypes",
    element: <BlogTypes />,
  },
  {
    path: "/admin/blogs",
    name: "blogs",
    element: <Blogs />,
  },
  {
    path: "/admin/blogs/create",
    name: "CreateBlog",
    element: <CreateBlog />,
  },
  {
    path: "/admin/blogs/update/:id",
    name: "CreateBlog",
    element: <CreateBlog />,
  },
  {
    path: "/admin/blogs/:id",
    name: "BlogDetails",
    element: <BlogDetails />,
  },
  {
    path: "/admin/jobs",
    name: "Jobs",
    element: <Jobs />,
  },
  {
    path: "/admin/jobs/:jobId",
    name: "JobDetails",
    element: <JobDetails />,
  },
  {
    path: "/admin/jobs/create",
    name: "CreateJob",
    element: <PostAJob />,
  },
  {
    path: "/admin/jobs/update/:jobId",
    name: "CreateJob",
    element: <PostAJob />,
  },
  {
    path: "/admin/templates",
    name: "Templates",
    element: <Templates />,
  },
  {
    path: "/admin/templates/create",
    name: "CreateTemplate",
    element: <CreateTemplate />,
  },
  {
    path: "/admin/templates/update/:id",
    name: "CreateTemplate",
    element: <CreateTemplate />,
  },
  {
    path: "/admin/candidates",
    name: "Candidates",
    element: <Candidates />,
  },
  {
    path: "/admin/candidates/:id",
    name: "CandidateDetails",
    element: <CandidateDetails />,
  },
  {
    path: "/admin/faqs",
    name: "Faqs",
    element: <Faqs />,
  },
  {
    path: "/admin/faqs/:id",
    name: "FaqDetails",
    element: <FaqDetails />,
  },
  {
    path: "/admin/agreement",
    name: "Agreement",
    element: <Agreement />,
  },
  {
    path: "/admin/agreement/Terms",
    name: "Terms",
    element: <Terms />,
  },
  {
    path: "/admin/agreement/privacy-policy",
    name: "Policy",
    element: <Policy />,
  },
  {
    path: "/admin/categories",
    name: "Categories",
    element: <JobCategories />,
  },
];

export default ScreenRoutes;
