import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Toolbar,
  Menu,
  Typography,
  Box,
} from "@mui/material";
import { AppBar } from "./LayoutStyleComponents";
import { Images } from "utils/imagePath";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ME } from "./graphql/query";
import toast from "react-hot-toast";

const Navbar = ({ open, setOpen, matches }) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const { data } = useQuery(GET_ME, {
    fetchPolicy: "network-only",
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <AppBar position="fixed" open={open} matches={matches}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2 }}
        >
          <MenuIcon sx={{ fontSize: 30, color: "black" }} />
        </IconButton>
      </Toolbar>
      <IconButton sx={{ mr: 2 }} onClick={handleClick}>
        <Avatar alt="Remy Sharp" src={Images.AVATAR_IMG} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "12px",
            overflow: "visible",
            p: 1,
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            mt: 1.5,
            width: 180,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ px: 1.5 }}>
          <Stack direction="column">
            <Typography sx={{ fontWeight: 600 }}>
              {data?.me?.username ?? "-"}
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                color: "rgba(0,0,0,.5)",
              }}
            >
              {data?.me?.email ?? "-"}
            </Typography>
          </Stack>
        </Box>
        <Divider sx={{ mb: 1, mt: 2 }} />
        <MenuItem
          onClick={handleLogout}
          sx={{
            color: "#ff4d4f",
            p: 1.5,
            "&:hover": {
              backgroundColor: "rgba(255,77,79,.1)",
            },
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

export default Navbar;
