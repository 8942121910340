import { Box, Typography } from "@mui/material";
import List from "@mui/material/List";
import { NavLink, useLocation } from "react-router-dom";
import NestedList from "./NestedList";
import { isActivePage } from "utils";

export default function Link({ link }) {
  const location = useLocation();

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      sx={{
        width: "92%",
        margin: "0 auto",
        maxWidth: 360,
        bgcolor: "background.paper",
      }}
    >
      {link.routes.map((route, index) =>
        route.children ? (
          <NestedList key={index} route={route} />
        ) : (
          <NavLink
            key={`${route.label}-${index}`}
            exact={route.exact}
            to={route.link}
          >
            <Box
              sx={{
                display: "flex",
                padding: "10px",
                alignItems: "center",
                borderRadius: "7px",
                transition: "0.2s all",
                mb: 0.5,
                "&:hover": {
                  color: isActivePage(route.link, location)
                    ? "white"
                    : "primary.main",
                  bgcolor: isActivePage(route.link, location)
                    ? "primary.main"
                    : "primary.light",
                },
                color: isActivePage(route.link, location)
                  ? "white"
                  : "primary.main",
                backgroundColor: isActivePage(route.link, location)
                  ? "primary.main"
                  : "",
              }}
            >
              <Box
                sx={{
                  height: 30,
                  width: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: "primary.dark",
                  borderRadius: 1,
                  mr: 1.5,
                  color: "white",
                }}
              >
                {route.icon}
              </Box>
              <Typography sx={{ fontWeight: 500, fontSize: 16 }}>
                {route.label}
              </Typography>
            </Box>
          </NavLink>
        )
      )}
    </List>
  );
}
