export const USER_TOKEN = "token";
export const DRAWER_WIDTH = 250;
export const INITIAL_DATA_TABLE_LIMIT = 25;
export const COMMON_BOX_SHADOW =
  "rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px";

export const BASE_DIR =
  process.env.NEXT_PUBLIC_BUILD_TYPE === "DEV"
    ? "wrightalentstage/"
    : "wrightalent/";
export const JOB_TYPE_LIST = [
  {
    display: "Full Time",
    key: "full_time",
  },
  {
    display: "Part Time",
    key: "part_time",
  },
  {
    display: "Contract",
    key: "contract",
  },
  {
    display: "Freelance",
    key: "freelance",
  },
  {
    display: "Internship",
    key: "internship",
  },
  {
    display: "Temporary",
    key: "temporary",
  },
  {
    display: "Volunteer",
    key: "volunteer",
  },
  {
    display: "Apprenticeship",
    key: "apprenticeship",
  },
];
export const EXPERTISE_LEVEL_LIST = [
  {
    display: "Entry Level",
    key: "entry",
  },
  {
    display: "Junior",
    key: "junior",
  },
  {
    display: "Mid Level",
    key: "mid",
  },
  {
    display: "Senior",
    key: "senior",
  },
];
export const QUALIFICATION_LIST = [
  {
    display: "High School",
    key: "high_school",
  },
  {
    display: "Associate",
    key: "associate",
  },
  {
    display: "Bachelors",
    key: "bachelors",
  },
  {
    display: "Masters",
    key: "masters",
  },
  {
    display: "PHD",
    key: "phd",
  },
];
export const GENDER_LIST = [
  {
    display: "Male",
    key: "male",
  },
  {
    display: "Female",
    key: "female",
  },
];
export const IS_ACTIVE = [
  { display: "Active", key: true },
  { display: "In Active", key: false },
];

export const STATUS_COLOR = {
  expired: "#B71C19",
  active: "#19806A",
  inactive: "#B76D00",
  pending: "#000000",
  "in-progress": "#B76D00",
  "partially-delivered": "#fff",
  delivered: "#19806A",
  selected: "#19806A",
  cancelled: "#B71C19",
  approved: "#19806A",
  accepted: "#000000",
  completed: "#fff",
  rejected: "#B71C19",
  "waiting-for-cancellation": "#B71C19",
};

export const STATUS_BACKGROUND_COLOR = {
  expired: "#F9DED9",
  active: "#DBEBE4",
  inactive: "#F8EAD8",
  pending: "#0000001a",
  "in-progress": "#F8EAD8",
  "partially-delivered": "#81c784",
  delivered: "#DBEBE4",
  selected: "#DBEBE4",
  cancelled: "#F9DED9",
  approved: "#DBEBE4",
  accepted: "#ffeb3b",
  completed: "#19806A",
  rejected: "#F9DED9",
  "waiting-for-cancellation": "#F9DED9",
};
