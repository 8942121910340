import { each } from "lodash";
import moment from "moment";
import s3BucketApiCallWrapper from "./S3BucketApi";
import toast from "react-hot-toast";

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isActivePage = (link, location) => {
  return location.pathname.split("/")[2] === link.split("/")[2];
};

export const setTextLength = (text, length) => {
  if (text.length < length) return text;
  else return text.slice(0, length).concat("...");
};

export const getFilteredValue = (filter, skipIfFalse) => {
  const copyFilter = { ...filter };
  let newFilter = {};
  each(copyFilter, (val, key) => {
    if (typeof val.value === "boolean" || val.value) {
      newFilter[key] = val.value;
    }
    if (val.type === "date") {
      if (!val.value || !moment(val.value).isValid()) {
        delete newFilter[key];
      } else {
        newFilter[key] = moment(val.value).format("YYYY-MM-DD");
      }
    }
  });
  return newFilter;
};

export function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}
export function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
}
export const getSizeInKb = (value) => {
  let KB = value / 1024;
  return `${Math.floor(KB)}`;
};
export const handleUploadMediaToBucket = async (file, dir) => {
  try {
    const method = {
      apiType: "POST",
      payload: file,
      dir: dir,
    };
    const result = await s3BucketApiCallWrapper(method);
    return result.location;
  } catch (error) {
    toast.error("Failed to upload attachment");
  }
};
export const handleDeleteAttachmentFromS3 = async (link, dir) => {
  try {
    const method = {
      apiType: "DELETE",
      payload: link,
      dir: dir,
    };

    await s3BucketApiCallWrapper(method);
    return true;
  } catch (error) {
    toast.error("Failed to delete attachment");
  }
};
