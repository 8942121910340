import Layout from "components/layout";
import { Navigate } from "react-router-dom";
import { USER_TOKEN } from "utils/constants";

const ProtectedRoutes = ({ children }) => {
  let token = localStorage.getItem(USER_TOKEN);

  return token ? <Layout>{children}</Layout> : <Navigate to="/admin/login" />;
};

export default ProtectedRoutes;
