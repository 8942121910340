import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { USER_TOKEN } from "utils/constants";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(USER_TOKEN)
    ? `JWT ${localStorage.getItem(USER_TOKEN)}`
    : "";

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    // Graphql Error hapenning
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions, locations, path }) => {
        if (
          message === "Signature has expired" ||
          message === "You are not authorized user."
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("refresh");
          window.location.href = "/admin/login";
        }
      });
    }

    // If network error happening
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);
export const BASE_URL = process.env.REACT_APP_BASE_URL;

const link = from([
  errorLink,
  new createUploadLink({ uri: `${BASE_URL}graphql/` }),
]);

const options = {
  typePolicies: {
    Query: {
      fields: {
        allBlogCategories: relayStylePagination(),
        responsibilitiesList: relayStylePagination(),
        companyList: relayStylePagination(),
        universityList: relayStylePagination(),
        singleCompany: relayStylePagination(),
        jobs: relayStylePagination(),
        users: relayStylePagination(),
        categories: relayStylePagination(),
        industryList: relayStylePagination(),
        allRecruiterRequests: relayStylePagination(),
        designationList: relayStylePagination(),
        users: relayStylePagination(),
        allBlogTypes: relayStylePagination(),
        allPosts: relayStylePagination(),
        candidateList: relayStylePagination(),
        jobList: relayStylePagination(),
        allFaqs: relayStylePagination(),
      },
    },
  },
};

export const Client = new ApolloClient({
  cache: new InMemoryCache(options),
  link: authLink.concat(link),
});
