import { ThemeProvider } from "@mui/material";
import "./App.css";
import { theme } from "./theme";
import { Toaster } from "react-hot-toast";
import AppRoutes from "./routes";
import { Suspense } from "react";
import PageLoading from "components/layout/PageLoading";
import { ApolloProvider } from "@apollo/client";
import { Client } from "graphql/client";

function App() {
  return (
    <ApolloProvider client={Client}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<PageLoading />}>
          <AppRoutes />
          <Toaster position="top-right" reverseOrder={false} />
        </Suspense>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
