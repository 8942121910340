import { createTheme } from "@mui/material/styles";
// import colors from "utils/Colors";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#26A4FF1A",
      main: "#0079D1",
      dark: "#015ca3",
    },
    secondary: {
      light: "#33eb91",
      main: "#EFE244",
      dark: "#00a152",
      contrastText: "#000",
    },

    common: {
      black: "#121212",
      white: "#efefef",
      grey: "rgb(99, 115, 129)",
    },
    type: "light",
  },
  background: {
    paper: "#424242",
    default: "#303030",
  },
  text: {
    primary: "#fff",
    secondary: "rgba(255, 255, 255, 0.7)",
    disabled: "rgba(255, 255, 255, 0.5)",
    hint: "rgba(255, 255, 255, 0.5)",
    icon: "rgba(255, 255, 255, 0.5)",
    divider: "rgba(255, 255, 255, 0.12)",
  },
  typography: {
    htmlFontSize: 15,
    fontSize: 12,
    fontWeightMedium: "400",
    fontFamily: "Public Sans, sans-serif",
    h1: {
      fontSize: "44px",
      fontWeight: 700,
      color: "#202020",
    },
    h2: {
      fontSize: "40px",
      fontWeight: 600,
      color: "#202020",
    },
    h3: {
      fontSize: "36px",
      color: "#202020",
    },
    h4: {
      fontSize: "32px",
      color: "#202020",
    },
    h5: {
      fontSize: "28px",
      color: "#202020",
    },
    h6: {
      fontSize: "24px",
      color: "#202020",
    },
    subtitle1: {
      fontSize: "20px",
      color: "#202020",
    },
  },
  spacing: 6,
  //Override the componants
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          fontWeight: 600,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {},
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          boxShadow: "none",
          fontFamily: "Public Sans, sans-serif",
          fontSize: "1rem",
          fontWeight: 700,
          "&:hover": {
            textDecoration: "none",
            boxShadow: "none",
          },
        },
      },
    },
  },
});
