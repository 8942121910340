import { Box, Drawer } from "@mui/material";
import { DRAWER_WIDTH } from "utils/constants";
import { Images } from "utils/imagePath";
import NavLink from "./NavLink";

const Sidebar = ({ open, setOpen, matches }) => {
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      variant={matches ? null : "persistent"}
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
        },
      }}
      onClose={handleDrawerClose}
      anchor="left"
      open={open}
    >
      <Box sx={{ height: 37, width: "80%", marginX: "auto", my: 4 }}>
        <img
          src={Images.LOGO}
          alt="logo"
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
      <NavLink />
    </Drawer>
  );
};

export default Sidebar;
