import DashboardIcon from "@mui/icons-material/Dashboard";

import { Business, Category } from "@mui/icons-material";
import { FaRegNewspaper, FaUsers } from "react-icons/fa6";
import { HiOutlineTemplate } from "react-icons/hi";
import { LiaIndustrySolid } from "react-icons/lia";
import { MdWorkOutline, MdTypeSpecimen } from "react-icons/md";
import { BiLogoBlogger } from "react-icons/bi";
import { FaQuestionCircle } from "react-icons/fa";
import Link from "./Link";

const linskData = [
  {
    label: "Application",
    routes: [
      {
        name: "Dashboard",
        label: "Dashboard",
        link: "/admin/dashboard",
        icon: <DashboardIcon fontSize="small" />,
      },
      {
        name: "users",
        label: "Users",
        link: "/admin/users",
        icon: <FaUsers fontSize="medium" />,
      },
      {
        name: "Companies",
        label: "Companies",
        link: "/admin/companies",
        icon: <Business fontSize="small" />,
      },
      {
        name: "Candidates",
        label: "Candidates",
        link: "/admin/candidates",
        icon: <FaUsers fontSize="medium" />,
      },
      {
        name: "BecomeRecruiterRequests",
        label: "Become Recruiter Requests",
        link: "/admin/becomeRecruiterRequests",
        icon: <Business fontSize="small" />,
      },
      {
        name: "Universities",
        label: "Universities",
        link: "/admin/universities",
        icon: <Business fontSize="small" />,
      },
      {
        name: "Jobs",
        label: "Jobs",
        link: "/admin/jobs",
        icon: <MdWorkOutline fontSize="medium" />,
      },
      {
        name: "Blogs",
        label: "Blogs",
        icon: <BiLogoBlogger fontSize="small" />,
        children: [
          {
            name: "Blogs",
            label: "Blogs",
            link: "/admin/blogs",
            icon: <BiLogoBlogger fontSize="small" />,
          },
          {
            name: "Blog Categories",
            label: "Blog Categories",
            link: "/admin/blogCategories",
            icon: <Category fontSize="small" />,
          },
          {
            name: "Blog Types",
            label: "Blog Types",
            link: "/admin/blogTypes",
            icon: <MdTypeSpecimen fontSize="small" />,
          },
        ],
      },
      {
        name: "Industries",
        label: "Industries",
        link: "/admin/industries",
        icon: <LiaIndustrySolid fontSize="medium" />,
      },
      {
        name: "Job Category",
        label: "Job Category",
        link: "/admin/categories",
        icon: <LiaIndustrySolid fontSize="medium" />,
      },
      {
        name: "Designations",
        label: "Designations",
        link: "/admin/designations",
        icon: <LiaIndustrySolid fontSize="medium" />,
      },
      {
        name: "templates",
        label: "Templates",
        link: "/admin/templates",
        icon: <HiOutlineTemplate fontSize="medium" />,
      },
      {
        name: "faqs",
        label: "Faqs",
        link: "/admin/faqs",
        icon: <FaQuestionCircle fontSize="medium" />,
      },
      {
        name: "agreement",
        label: "Agreement",
        link: "/admin/agreement/privacy-policy",
        icon: <FaRegNewspaper fontSize="medium" />,
      },
    ],
  },
];

const NavLink = ({ selected, setSelected }) => {
  return linskData.map((link, index) => (
    <Link
      key={index}
      link={link}
      selected={selected}
      setSelected={setSelected}
    />
  ));
};

export default NavLink;
