import { BASE_DIR } from "./constants";
import S3 from "react-aws-s3";

if (typeof window !== "undefined") {
  window.Buffer = window.Buffer || require("buffer").Buffer;
}

export async function s3BucketApiCall(method) {
  const config = {
    bucketName: "w3storage",
    dirName: method.dir ? `${BASE_DIR}${method.dir}` : `${BASE_DIR}`,
    region: "ap-south-1",
    accessKeyId: "AKIA3HVKM6KEHA37LSGC",
    secretAccessKey: "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",
  };
  const ReactS3Client = new S3(config);
  if (method.apiType === "DELETE") {
    return ReactS3Client.deleteFile(method.payload);
  } else {
    return ReactS3Client.uploadFile(method.payload);
  }
}

export default async function s3BucketApiCallWrapper(method) {
  return new Promise((resolve, reject) => {
    s3BucketApiCall(method)
      .then((response) => {
        resolve(response || {});
      })
      .catch((err) => {
        reject(err);
      });
  });
}
