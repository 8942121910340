import React, { lazy } from "react";

import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import ProtectedRoutes from "./protectedRoutes";
import ScreenRoutes from "./screenRoutes";
import { USER_TOKEN } from "utils/constants";

const Login = lazy(() => import("pages/login"));

const AppRoutes = () => {
  let token = localStorage.getItem(USER_TOKEN);

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            token ? (
              <Navigate to="/admin/dashboard" replace />
            ) : (
              <Navigate to="/admin/login" replace />
            )
          }
        />

        <Route
          path="/admin/login"
          element={
            token ? <Navigate to="/admin/dashboard" replace /> : <Login />
          }
        />

        <Route path="" name="Home" element={<ProtectedRoutes />}>
          {ScreenRoutes.map((route, idx) => {
            return (
              <Route
                key={`Layout_${idx}`}
                path={route.path}
                name={route.name}
                element={route.element}
              />
            );
          })}
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
