import { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Main } from "./LayoutStyleComponents";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

const Layout = () => {
  const matches = useMediaQuery("(max-width:600px)");

  const [open, setOpen] = useState(true);

  return (
    <>
      <Navbar open={open} setOpen={setOpen} matches={matches} />
      <Sidebar open={open} setOpen={setOpen} matches={matches} />
      <Main open={open} matches={matches}>
        <Box sx={{ p: 2 }}>
          <Outlet />
        </Box>
      </Main>
    </>
  );
};

export default Layout;
