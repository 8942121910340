import { Box, useTheme } from "@mui/material";
import { MoonLoader } from "react-spinners";

const PageLoading = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "rgba(0,0,0,.15)",
      }}
    >
      <MoonLoader color={theme.palette.primary.main} size={50} />
    </Box>
  );
};

export default PageLoading;
