import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

import { Box, Typography } from "@mui/material";
import { NavLink, useLocation } from "react-router-dom";
import { isActivePage } from "utils";

export default function NestedList({ route }) {
  const location = useLocation();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        style={{
          display: "flex",
          padding: "10px",
          alignItems: "center",
          borderRadius: "7px",
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            height: 30,
            minWidth: 30,
            width: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "primary.dark",
            borderRadius: 1,
            mr: 1.5,
            color: "white",
          }}
        >
          {route.icon}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "primary.main",
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: 16 }}>
            {route.label}
          </Typography>

          {open ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {route.children.map((child, index) => {
            return child.children ? (
              <NestedList key={index} route={child} />
            ) : (
              <NavLink exact={child.exact} key={index} to={child.link}>
                <Box
                  sx={{
                    display: "flex",
                    padding: "10px",
                    alignItems: "center",
                    borderRadius: "7px",
                    transition: "0.2s all",
                    ml: 2,
                    "&:hover": {
                      bgcolor: isActivePage(child.link, location)
                        ? "primary.main"
                        : "primary.light",
                    },
                    color: isActivePage(child.link, location)
                      ? "white"
                      : "primary.main",
                    backgroundColor: isActivePage(child.link, location)
                      ? "primary.main"
                      : "",
                  }}
                >
                  <Box
                    sx={{
                      height: 30,
                      width: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      bgcolor: "primary.dark",
                      borderRadius: 1,
                      mr: 1.5,
                      color: "white",
                    }}
                  >
                    {child.icon}
                  </Box>
                  <Typography sx={{ fontWeight: 500, fontSize: 16 }}>
                    {child.label}
                  </Typography>
                </Box>
              </NavLink>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}
